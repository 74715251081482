
import { defineComponent } from "vue";
import { TableEditingConfig } from "@/components/controls/catalogueGrid/tableEditingConfig";
import { TableSelectionConfig } from "@/components/controls/catalogueGrid/tableSelectionConfig";
import { TableDesignConfig } from "@/components/controls/catalogueGrid/tableDesignConfig";
import { TextColumnConfig } from "@/components/controls/catalogueGrid/columns/textColumnConfig";
import { IntegerColumnConfig } from "@/components/controls/catalogueGrid/columns/integerColumnConfig";
import { requiredRule } from "@/components/controls/catalogueGrid/columns/baseValidationRules";
import CatalogueGrid from "@/components/controls/catalogueGrid/CatalogueGrid.vue";
import { EnumColumnConfig } from "@/components/controls/catalogueGrid/columns/enumColumnConfig";
import { DateColumnConfig } from "@/components/controls/catalogueGrid/columns/dateColumnConfig";
import planInfoDialog from "@/components/info/PlanInfoDialog.vue";

export default defineComponent({
  components: {
    CatalogueGrid,
  },
  props: {
    //установленные фильтры
    filters: { required: false },
    //панель выбора страницы
    showPageSizeSelector: { required: false, default: true },
    //показатель режима выбора
    selectMode: { required: false, type: Boolean, default: false },
    gridHeight: { required: false, type: String, default: null },
    //функция для получения выбранных элементов
    getSelectedData: { type: Function },
    //функция для вызова при изменении выбранных элементов
    changeSelectedData: { type: Function },
  },
  computed: {
    planInfoDialog(): any {
      return planInfoDialog;
    },
  },
  methods: {
    onContentReady(e: any) {
      if (!this.customFilterApplied && this.filters) {
        const grid = e.component;
        this.filters.forEach((x: { column: any; value: any }) => {
          grid.columnOption(x.column, "filterValue", x.value);
        });
        this.customFilterApplied = true;
      }
    },
  },
  data() {
    return {
      customFilterApplied: false,
      editingConfig: new TableEditingConfig({
        allowUpdating: true,
        allowAdding: true,
        allowDeleting: true,
        confirmDelete: true,
        allowDataFill: false,
        allowClearFilter: true,
        allowDeleteMultipleRows: false,
        pageEditing: true,
        allowCopy: true,
        pageName: "plan",
        showInfo: true,
      }),
      selectModeEditingConfig: new TableEditingConfig({
        allowUpdating: false,
        allowAdding: false,
        allowDeleting: false,
        confirmDelete: false,
        allowDataFill: false,
        allowExport: false,
        allowDeleteMultipleRows: false,
      }),
      columns: [
        new IntegerColumnConfig({
          dataField: "id",
          caption: "ID плана",
          readOnly: true,
          editVisible: false,
          format: ""
        }),
        new TextColumnConfig({
          dataField: "name",
          caption: "Наименование",
          validationRules: [requiredRule],
        }),
        new EnumColumnConfig({
          dataField: "stateDescription",
          caption: "Тип",
          enumType: "planState",
          modelField: "state",
          modelDescField: "stateDescription",
          descriptionColumnName: "stateDescription",
          validationRules: [requiredRule],
        }),
        new DateColumnConfig({
          dataField: "calcCompletedDate",
          caption: "Дата расчета",
          mode: "datetime",
        }),
        new DateColumnConfig({
          dataField: "creationDate",
          caption: "Дата создания",
          mode: "datetime",
          validationRules: [requiredRule],
          sortOrder: "desc",
        }),
        new TextColumnConfig({
          dataField: "calcUserDescription",
          caption: "Пользователь",
          validationRules: [requiredRule],
        }),
      ],
      designConfig: new TableDesignConfig({
        popUpColumnsCount: 1,
        popUpWidth: "850px",
        heightScroll: !this.selectMode ? "calc(100vh - 205px)" : "400px",
        horizontalScroll: true,
      }),
      selectionConfig: new TableSelectionConfig({
        mode: "single",
      }),
      pageSizes: [10, 20, 50, 100],
    };
  },
  created() {
    if (this.selectMode) {
      this.editingConfig = this.selectModeEditingConfig;
    }
    if (this.gridHeight) {
      this.designConfig.height = this.gridHeight;
    }
  },
});
